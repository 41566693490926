import React from 'react'

import {
    CombinationFinderSegment,
    FinderTitle,
    InfoBar,
    VehicleTypeOptions,
    VehicleTypeListItem,
    VehicleTypeItem,
    CombinationLetterCounterContainer,
    CombinationLetterCounter,
    CombinationActionContainer,
    CombinationInputRow,
    FinderSubTitle,
    StyledQuickCombinationImageContainer,
    StyledCombinationFinderBgImage,
    StyledCombinationInput,
    CombinationInputGrid,
    StyledQuickCombinationContentArea,
    ResetCombination,
    CombinationSearchResult
} from './CombinationFinder.styles'
import { List,  Grid } from 'semantic-ui-react';
import { KpButton } from '@elements/index';
import theme from '@styles/theme';
import { VehicleType, CombinationSettings,VehicleTypesSetting, TransactionTypesSetting, TransactionType } from '@models/ProductModels';
import QuickHelpInfoLink from './QuickHelpInfo';
import { ButtonType } from '@utils/KontentTypes';
import { CheckCombinationService } from '@services/CheckCombinationService';
import ReCaptcha from '@utils/reCaptcha';
import { ReCaptchaService } from '@services/ReCaptchaService';
import { ReCaptchaThreshold } from '@utils/Constant';
export interface StyledCombinationFinderProps{
    apiUrl: string
    title:string
    subtitle?: string
    hideVehicleOptions?: boolean
    plateBackgroundImage: string
    button: ButtonType
    plateImageWidth: number
    plateImageHeight: number
    plateHeaderRatio: number
    reCaptchaSiteKey: string
    reCaptchaSiteKeyV2: string
}
interface FinderStates{
    vehicleType: VehicleType
    combination:string
    combinationChecking: boolean
    oldCombination: string
    characterCombinationCount: number
    spacingCombinationCount: number
    transactionType?: TransactionType,
    combinationChecked: boolean,
    combinationAvailable: boolean,
    reCaptchaToken?: string,
    reCaptchaError: string
}

class StyledQuickCombinationFinder extends React.Component<StyledCombinationFinderProps,FinderStates>{

    constructor(props:StyledCombinationFinderProps){
        super(props);
        this.state = {
            vehicleType: VehicleTypesSetting.Car,
            combination: '',
            oldCombination: '',
            combinationChecking: false,
            characterCombinationCount: 0,
            spacingCombinationCount: 0,
            transactionType: undefined,
            combinationChecked: false,
            combinationAvailable: false,
            reCaptchaToken: undefined,
            reCaptchaError: ""
        }
    }

    getCombinationWidth = (combination: string) => {

        const largeCharLength = combination.replace(CombinationSettings.largeCharPattern, "").length;
        const smallCharLength = combination.replace(CombinationSettings.smallCharPattern, "").length;
        const spaceLength = combination.replace(CombinationSettings.spacePattern, "").length;
        const totalLength = (largeCharLength * this.state.vehicleType.largeCharWidth) + (smallCharLength * this.state.vehicleType.smallCharWidth) + (spaceLength * this.state.vehicleType.spaceCharWidth);

        return {
            largeCharacterLength: largeCharLength,
            smallCharacterLength: smallCharLength,
            spaceLength: spaceLength,
            totalWidth: totalLength
        }
    }

    handleInputChange = (event:any, widgetV2: number | undefined) =>{
        this.setState({ combination: event.target.value, combinationChecked: false });

        let combination = event.target.value;
        const combinationWidth = this.getCombinationWidth(combination);

        if(document && document.getElementById("g-recaptcha_seachCombination-v2")){
            window.grecaptcha.reset(widgetV2);
        }

        if (combinationWidth.totalWidth <= this.state.vehicleType.maxWidth)
            this.setState({
                characterCombinationCount: combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength,
                spacingCombinationCount: combinationWidth.spaceLength
            })

        if ((combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength) > this.state.vehicleType.maxChars || combinationWidth.totalWidth > this.state.vehicleType.maxWidth) {
            this.setState({
                combination: this.state.oldCombination
            });

        } else {
            var transformedInput = combination.replace(CombinationSettings.allowed, "");
            if (transformedInput !== combination) {
                this.setState({ combination: transformedInput });
            }
            this.setState({
                oldCombination: transformedInput
            });
        }
    }

    handleCombinationCheck = (execute: () => Promise<string>, widgetV2: number | undefined) => {
        this.setState({
            combinationChecking: true
        });
        const combination = this.state.combination;
        this.verifyRecaptcha(combination, execute, widgetV2)
    }

    verifyRecaptcha = async (combination: string, execute: () => Promise<string>, widgetV2: number | undefined) => {
        let recaptcha_v2: HTMLElement | null
        if(document){
            recaptcha_v2 = document.getElementById("g-recaptcha_seachCombination-v2")
        } else {
            return
        }

        if(recaptcha_v2 && recaptcha_v2.style.display == "block"){
            const token = window.grecaptcha.getResponse(widgetV2)
            ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v2").then(async (response: any) => {
                if (response && response.data.Success)
                {
                    this.setState({reCaptchaError: ""})
                    this.checkCombinationAvailable(combination).then((data) => {
                        if(data.available){
                            this.setState({transactionType: TransactionTypesSetting.New,combinationChecking: false, combinationChecked: true, combinationAvailable: true })
                        }
                        else{
                            this.setState({transactionType: undefined,combinationChecked: true, combinationChecking: false,combinationAvailable: false })
                        }
                    });
                } else {
                    this.setState({reCaptchaError: "Please tick checkbox correctly"})
                    this.setState({combinationChecking: false})
                    return false
                }
            })
        } else {
            const token = await execute()
            this.setState({ reCaptchaToken: token })
            ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v3").then(async (response: any) => {
                if (response.status != 200) return 'recaptcha validate error'
                if (response.data.Data && (!response.data.Data.success || response.data.Data.score < ReCaptchaThreshold)) {
                    console.log(response.data.Data.score)
                    if (document && recaptcha_v2){
                        recaptcha_v2!.style.display = "block"
                        this.setState({combinationChecking: false})
                    }
                    return false
                } else {
                    this.checkCombinationAvailable(combination).then((data) => {
                        if(data.available){
                            this.setState({transactionType: TransactionTypesSetting.New,combinationChecking: false, combinationChecked: true, combinationAvailable: true })
                        }
                        else{
                            this.setState({transactionType: undefined,combinationChecked: true, combinationChecking: false,combinationAvailable: false })
                        }
                    });
                }
            })
        }
    }

    buyAsNewPlate = () =>{

    }
    checkCombinationAvailable = (combination: string) => {
        return CheckCombinationService.checkCombination(this.props.apiUrl,combination, this.state.vehicleType.id).then((combinationCheckResult) => {
            return {
                available: combinationCheckResult.Available,
                availableForRedesign: combinationCheckResult.IsAvailableForRedesign,
                availableForUpgrade: combinationCheckResult.IsAvailableForUpgrade
            }
        });
    }

    resetCombination = () => {
        this.setState({
            combination: '',
            combinationChecked: false,
            combinationAvailable: false,
            transactionType: undefined,
            characterCombinationCount: 0
        });
    }

    handleSubmit = (event:any) =>{

    }

    changeVehicleType = (vehicleType: VehicleType) => {
        this.setState({
            vehicleType: vehicleType,
            combination: '',
            characterCombinationCount: 0
        });
    }

    render(){
        const {title, subtitle, hideVehicleOptions,plateImageHeight,plateImageWidth,plateHeaderRatio,plateBackgroundImage, button} = this.props;
        const {vehicleType,combination,characterCombinationCount, combinationAvailable, combinationChecked, combinationChecking} = this.state;
        const inputDesktopMaxWidth = 750;
        const inputTabletMaxWidth = 750;
        const inputMarginTopRatio = plateImageHeight * (1-plateHeaderRatio)/plateImageWidth;

        return (
        <CombinationFinderSegment basic>
            <ReCaptcha action="seachCombination" reCaptchaSiteKey={this.props.reCaptchaSiteKey} reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}>{captcha => (
            <StyledQuickCombinationContentArea mobileWidth="100%" tabletWidth={`${inputTabletMaxWidth}px`} desktopWidth={`${inputDesktopMaxWidth}px`} >
                <FinderTitle margined={subtitle === undefined} color={theme.brand.colors.white} textAlign='center'>
                    {title}
                </FinderTitle>
                {
                    subtitle &&
                    <FinderSubTitle color={theme.brand.colors.white} textAlign='center'>{subtitle}</FinderSubTitle>
                }

                <InfoBar>
                    <VehicleTypeOptions>
                        <List horizontal>
                            <VehicleTypeListItem>
                                <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Car.name} color={theme.brand.colors.white} selected={true} onClick={() => this.changeVehicleType(VehicleTypesSetting.Car)}>{VehicleTypesSetting.Car.name}</VehicleTypeItem>
                            </VehicleTypeListItem>
                            {
                            !hideVehicleOptions &&
                                <VehicleTypeListItem>
                                    <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Trailer.name} selected={vehicleType.name === VehicleTypesSetting.Trailer.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Trailer)}>{VehicleTypesSetting.Trailer.name}</VehicleTypeItem>
                                </VehicleTypeListItem>
                            }
                            {
                            !hideVehicleOptions &&
                                <VehicleTypeListItem>
                                    <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Motorbike.name} selected={vehicleType.name === VehicleTypesSetting.Motorbike.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Motorbike)}>{VehicleTypesSetting.Motorbike.name}</VehicleTypeItem>
                                </VehicleTypeListItem>
                            }
                        </List>
                        <QuickHelpInfoLink darkTheme={false} />

                    </VehicleTypeOptions>
                    <CombinationLetterCounterContainer>
                        <CombinationLetterCounter color={theme.brand.colors.white}>
                            {characterCombinationCount}/{vehicleType.letters}
                        </CombinationLetterCounter>
                    </CombinationLetterCounterContainer>
                </InfoBar>
                <StyledQuickCombinationImageContainer>
                    <StyledCombinationFinderBgImage src={plateBackgroundImage} />
                    <CombinationInputGrid
                        mobileMargin={`${inputMarginTopRatio * 100}vw`}
                        tabletMargin={`${inputMarginTopRatio * inputTabletMaxWidth}px`}
                        desktopMargin={`${inputMarginTopRatio * inputDesktopMaxWidth}px`}>
                        <CombinationInputRow centered>
                            <Grid.Column textAlign='center' width={16}>
                                <StyledCombinationInput aria-label='Search Your Combination' spellCheck='false' autoComplete='off' autoCorrect='off' placeholder={ this.state.vehicleType.id === 1 ? 'ABC123' : 'ABC12' } onChange={(event) => this.handleInputChange(event, captcha.widgetV2)} value={combination} />
                            </Grid.Column>
                        </CombinationInputRow>
                    </CombinationInputGrid>
                </StyledQuickCombinationImageContainer>
                {
                    combinationChecked && combinationAvailable &&
                    <CombinationSearchResult color='white' available={true}>
                        Your Combination is Available!
                    </CombinationSearchResult>
                }
                {
                    combinationChecked && !combinationAvailable &&
                    <CombinationSearchResult available={false}>
                        Your Combination is not Available!
                    </CombinationSearchResult>
                }
                <CombinationActionContainer>
                    <Grid.Row>
                        <div className="g-recaptcha-v2" data-sitekey={this.props.reCaptchaSiteKeyV2} id="g-recaptcha_seachCombination-v2" style={{display:"none"}}></div>
                    </Grid.Row>
                    {
                        !combinationChecked &&
                        <KpButton fullWidth="mobile" id="cfca" loading={combinationChecking} disabled={characterCombinationCount <= 0} color={button.theme} buttonType='primary' onClick={() => this.handleCombinationCheck(captcha.execute, captcha.widgetV2)}>
                            {button.text}
                        </KpButton>
                    }
                    {
                        combinationChecked && combinationAvailable &&
                        <div>
                            <KpButton fullWidth="mobile" id='cfc' color={theme.brand.colors.blue} buttonType='primary' link={`${button.url}?fcomb=${combination}&vt=${vehicleType.id}`}>
                                Continue
                            </KpButton><br/><br/>
                            <ResetCombination id='cfrc' onClick={this.resetCombination}>
                                Reset Combination
                            </ResetCombination>
                        </div>
                    }
                    {
                        combinationChecked && !combinationAvailable &&
                        <KpButton fullWidth="mobile" id='cfsa' textColor={theme.brand.colors.white} color={theme.brand.secondary} buttonType='primary' onClick={this.resetCombination}>
                            Start Again
                        </KpButton>
                    }
                </CombinationActionContainer>
            </StyledQuickCombinationContentArea>
            )}</ReCaptcha>
        </CombinationFinderSegment>
        );
    }
}

export default StyledQuickCombinationFinder;